import { GlobalEventsService } from '../services/global-events.service';
import { PwaService } from '../services/pwa.service';
import { createInitializerQueueList } from './initializer-manager';
import { StagDetectorService } from '../services/stag-detector.service';
import { SeoService } from '../services/seo.service';
import { APP_INITIALIZER, Injector, Provider } from '@angular/core';
import { DecoratorService } from '../helpers/decorator-service.service';
import { InstallAppService } from '../services/install-app.service';
import { UserPaymentModeService } from '../services/user/user-payment-mode.service';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';

/**
 * The `initApp` function initializes the DecoratorService with the provided Injector.
 * @param {Injector} injector - The `injector` parameter in the `initApp` function is of type
 * `Injector`. It is used to provide dependencies to the application components. The `Injector` is a
 * dependency injection container that resolves dependencies for classes and services in Angular
 * applications.
 * @returns A function is being returned.
 */
export function initApp(injector: Injector) {
  return () => {
    DecoratorService.injector = injector;
  };
}

export const provideAppInitializer = () => {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Injector],
      multi: true,
    },
    ...parallelAppInitializer,
  ];
};

const parallelAppInitializer: Provider[] = createInitializerQueueList([
  {
    token: StagDetectorService,
    initializerFn: (service: StagDetectorService) => service.detectStag(),
  },
  {
    token: GlobalEventsService,
    initializerFn: (service: GlobalEventsService) => service.initGlobalEvent(),
  },
  {
    token: GoogleTagManagerService,
    initializerFn: (service: GoogleTagManagerService) => service.addGtmToDom(),
  },
  {
    token: PwaService,
    initializerFn: (service: PwaService) => service.registerServiceWorker(),
  },
  {
    token: SeoService,
    initializerFn: (service: SeoService) => service.updateGlobalHeadLink(),
  },
  {
    token: InstallAppService,
    initializerFn: (service: InstallAppService) => service.resolveShowVariables(),
  },
  {
    token: UserPaymentModeService,
    initializerFn: (service: UserPaymentModeService) => service.resolveModeFromUrl(),
  },
]);

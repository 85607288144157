import { Component } from '@angular/core';
import { DataPreloaderService } from './core/services/data-preloader.service';
import { OneSignalService } from './core/services/one-signal.service';
import { MainPreloaderComponent } from './core/shared/components/main-preloader/main-preloader.component';

import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    MainPreloaderComponent
],
})
export class AppComponent {
  constructor(
    public loader: DataPreloaderService,
    private _oneSignal: OneSignalService,
  ) {

  }
}

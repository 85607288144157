import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from 'ngx-unificator/tokens';
import { PlatformService } from '../services/platform.service';

export interface GoogleTagManagerConfig {
  id: string | null;
  gtm_auth?: string;
  gtm_preview?: string;
  [key: string]: string | null | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private _isLoaded = false;
  public config: GoogleTagManagerConfig = { id: 'GTM-5DL694W6' };

  constructor(
    private _platform: PlatformService,
    @Inject(WINDOW) private _window: Window,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  public getDataLayer(): any[] {
    const window = this._window;
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  public addGtmToDom() {
    return new Promise((resolve, reject) => {
      if (this._isLoaded) {
        return resolve(this._isLoaded);
      }
      const doc = this._document;
      this.pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        'event': 'gtm.js',
      });
      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this.applyGtmQueryParams(
        this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js',
      );
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
      if (this._platform.isBrowser) {
        gtmScript.addEventListener('load', () => {
          return resolve((this._isLoaded = true));
        });
        gtmScript.addEventListener('error', () => {
          return reject(false);
        });
      } else {
        return resolve((this._isLoaded = true));
      }
    });
  }

  public pushTag(item: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this._isLoaded) {
        this.addGtmToDom()
          .then(() => {
            this.pushOnDataLayer(item);
            return resolve();
          })
          .catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }

  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter(k => this.config[k])
        .map(k => `${k}=${this.config[k]}`)
        .join('&')
    );
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { PlatformService } from '../services/platform.service';
import { CookieService } from 'ngx-unificator/services';
import { environment } from '../../../environments/environment';
import { devDomain } from 'src/config/dev.domain';

const HOST = environment.error_host;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  /**
   * List of app hosts from which request urls should not be modified
   *
   * @private
   */
  private _ignoredHosts = [
    'https://localhost:4200',
    'https://stage.allstarcasino.com',
    'https://dev.allstarcasino.com',
    'https://allstarcasino.com',
    ...devDomain,
  ];

  constructor(private _platform: PlatformService, private _http: HttpClient, private _cookie: CookieService) {}

  /**
   * Handle all errors in application
   *
   * @param error
   */
  handleError(error: any) {
    if (this._platform.isBrowser) {
      const chunkFailedMessage1 = /Loading chunk [\d]+ failed/;
      const chunkFailedMessage2 = /Failed to fetch dynamically imported module/;
      if (chunkFailedMessage1.test(error.message) || chunkFailedMessage2.test(error.message)) {
        this._clearCacheWorker();
      }

      delete error.ngDebugContext;
      delete error.ngErrorLogger;

      if (error.rejection) {
        error = error.rejection;
      }

      switch (true) {
        case error instanceof HttpErrorResponse:
          this._sendError({
            ...error,
            message: `Failed HTTP request for url: ${(error as HttpErrorResponse).url}`,
          });
          break;
        default:
          if (!error.type) {
            error.type = 'frontend';
          }

          console.log(error);

          this._sendError({
            ...error,
            message: error.message || (error as Error).stack || 'Unknown message',
          });
          break;
      }
    }
  }

  /**
   * Send error to backend
   *
   * @param error
   * @private
   */
  private _sendError(error: Error) {
    if (!navigator.onLine) {
      return;
    }

    try {
      this._http
        .post(this._resolveHost(), {
          ts: Date.now(),
          userAgent: this._getUserAgent(),
          endpoint: this._getEndpointUrl(error),
          ...error,
        })
        .subscribe();
    } catch (e) {}
  }

  /**
   * Returns object containing information about user-agent and internet connection of user
   *
   * @private
   */
  private _getUserAgent() {
    const nav: any = navigator;
    return {
      location: window.location.href,
      language: nav.language || 'unknown',
      platform: nav.platform || 'unknown',
      userAgent: nav.userAgent || 'unknown',
      connectionDownlink: nav.connection ? nav.connection.downlink : 'unknown',
      connectionEffectiveType: nav.connection ? nav.connection.effectiveType : 'unknown',
      UID: this._cookie.get('UID') || 'unknown',
    };
  }

  /**
   * Return SS host or CMS host for endpoint field
   * @param error
   * @private
   */
  private _getEndpointUrl(error: Error) {
    switch (true) {
      case this._checkHost(error, environment.cms_host):
        return environment.cms_host;
      default:
        return environment.cms_host;
    }
  }

  // @ts-ignore
  private _checkHost(error: Error, host: string): boolean {
    const errorUrl = (error as HttpErrorResponse).url;
    if (errorUrl) {
      return errorUrl.startsWith(host);
    }
  }

  /**
   * Resolve host if mirror
   * @private
   */
  private _resolveHost() {
    if (this._platform.isBrowser) {
      const host = window.location.host;
      const hostName = host.split('.')[1];

      return !this._ignoredHosts.some(item => item.includes(host)) ? HOST.replace('allstarcasino', hostName) : HOST;
    } else {
      return HOST;
    }
  }

  private async _clearCacheWorker() {
    if ('serviceWorker' in navigator && 'caches' in window) {
      await navigator.serviceWorker
        .getRegistrations()
        .then(registrations => {
          for (let registration of registrations) {
            if (!registration.scope.includes('onesignal')) {
              registration
                .unregister()
                .then(success => {
                  console.log('Unregister old worker - ' + success);
                })
                .catch(error => {
                  console.log(error);
                });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
      await caches
        .keys()
        .then(cacheNameList => {
          cacheNameList.forEach(cacheName => {
            caches.delete(cacheName);
          });
        })
        .catch(error => {
          console.log(error);
        });
      setTimeout(() => {
        const paramType = window.location.href.indexOf('?') !== -1 ? '&' : '?';
        const newUrl = window.location.href + paramType + 'nocache=' + Date.now();
        window.location.href = newUrl;
      }, 500);
    }
  }
}
